import { gql } from '@apollo/client';

export const StoreFindAllByAccountIdGql = gql`
  query StoreFindAllByAccountId($account_id: Int!) {
    stores(where: { account_id: { _eq: $account_id } }) {
      account_id
      address
      city
      country
      id
      name
      province_state
      zip
    }
  }
`;

export const StoreUpdateInspectionGql = gql`
  mutation StoreUpdateInspection($id: Int!, $inspection_request: Boolean!) {
    update_vehicles_by_pk(
      pk_columns: { id: $id }
      _set: { inspection_request: $inspection_request }
    ) {
      id
      inspection_request
    }
  }
`;
