import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
  Button as ButtonRA,
  useNotify,
  useRefresh,
  useUnselectAll,
} from 'react-admin';
import { FormControlLabel, Switch } from '@material-ui/core';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';

import { ModalAction } from '../ui';
import { StoreBotOptionUpdateByStoreIdGql } from '../gql';
import { useToggle } from '../hooks';

const StoreEditPause = ({
  onSuccess,
  selectedIds = [],
  isPaused: isPausedProp,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, toggleIsOpen] = useToggle(false);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => setIsPaused(isPausedProp || []), [isPausedProp]);

  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll();

  const [updateStores] = useMutation(StoreBotOptionUpdateByStoreIdGql);

  const handleConfirm = async () => {
    let isSuccess = true;
    setIsLoading(true);

    for (const selectedId of selectedIds) {
      const { error } = await updateStores({
        variables: {
          store_id: selectedId,
          _set: { pause: isPaused },
        },
      });
      if (error) {
        isSuccess = false;
        notify('An error has occurred. Please try again later.', 'warning');
        break;
      }
    }

    setIsLoading(false);

    if (isSuccess) {
      notify(`${selectedIds.length} store(s) updated`);
      toggleIsOpen();
      refresh();
      unselectAll('stores');
      onSuccess?.();
    }
  };

  const isBulk = selectedIds.length >= 2;
  const confirmDescription = `Are you sure you want to ${
    isPaused ? 'pause' : 'unpause'
  } ${selectedIds.length} store(s)?`;

  return (
    <>
      <ButtonRA label="Toggle Pause" onClick={toggleIsOpen}>
        <ToggleOnIcon />
      </ButtonRA>
      <ModalAction
        confirmDescription={confirmDescription}
        isBulk={isBulk}
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={toggleIsOpen}
        onConfirm={handleConfirm}
        saveDescription={
          isBulk ? `Toggle pause for the stores.` : `Toggle pause of the store.`
        }
        saveTitle="Toggle Pause"
      >
        <FormControlLabel
          control={
            <Switch
              checked={isPaused}
              onChange={(e) => setIsPaused(e.target.checked)}
              name="pause-switch"
              color="primary"
            />
          }
          label="Pause"
        />
      </ModalAction>
    </>
  );
};

export default StoreEditPause;
