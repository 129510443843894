import React, { useState, useMemo } from 'react';
import { useFormik } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import { useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

import { ModalAction, TextField } from '../../ui';
import { buildRequestInspectionEmail, HasuraUrl } from '../../lib';
import {
  VehicleFindByIdGql,
  SendEmailGqlStr,
  StoreFindAllByAccountIdGql,
  StoreUpdateInspectionGql,
} from '../../gql';
import Locale from '../../locale/en';

const RequestInspectionFormSchema = Yup.object().shape({
  addressLine1: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  zip: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
});

const ModalRequestInspection = ({
  vehicleId,
  isOpen,
  isLoading,
  onClose,
  onConfirm,
}) => {
  const [updateInspectionRequest] = useMutation(StoreUpdateInspectionGql);
  const [presetStore, setPresetStore] = useState('');
  const classes = useStyles();
  const notify = useNotify();
  const vehicle = useQuery(VehicleFindByIdGql, {
    variables: { id: vehicleId },
    skip: !vehicleId,
    onCompleted: (data) => setPresetStore(data.vehicles[0].store.id),
  });

  const {
    created_at,
    km,
    make,
    model,
    offer = 0,
    offerRange = '',
    store = {},
    style,
    trim,
    user = {},
    vin,
    year,
    extra = {},
  } = vehicle.data?.vehicles?.[0] ?? {};

  const stores = useQuery(StoreFindAllByAccountIdGql, {
    variables: { account_id: store.account_id },
    skip: !store.account_id,
  });

  const initialValues = useMemo(() => {
    let storeUsed = store;
    if (presetStore) {
      const matchedStore = stores.data?.stores.find(
        ({ id }) => presetStore === id,
      );
      // Only change if something matched so address values will not clear
      if (matchedStore) {
        storeUsed = matchedStore;
      }
    }

    return {
      addressLine1: storeUsed.address || '',
      city: storeUsed.city || '',
      country: storeUsed.country || '',
      state: storeUsed.province_state || '',
      zip: storeUsed.zip || '',
    };
  }, [presetStore, store, stores]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: RequestInspectionFormSchema,
    onSubmit: (data) => {
      const send = async () => {
        const createdAt = new Date(created_at);

        const appraisalDate = createdAt.toLocaleDateString('en-US');
        createdAt.setDate(createdAt.getDate() + 7);

        const splitRange = offerRange
          .split(',')
          .map((i = '') => i.trim())
          .filter(Boolean);

        let emailOffer = `$${offer.toLocaleString()}`;
        if (splitRange.length >= 2) {
          emailOffer = splitRange
            .map((val) => `$${val.toLocaleString()}`)
            .join(' - ');
        }

        const { body, fromAlias, to, toAlias, from, subject } =
          buildRequestInspectionEmail({
            appraisalDate,
            city: data.city,
            country: data.country,
            disclosureAnswers: extra.disclosure_answers,
            disclosureQuestions: extra.disclosure_questions,
            mileage: km,
            offer: emailOffer,
            offerExpirationDate: createdAt.toLocaleDateString('en-US'),
            postalCode: data.zip,
            state: data.state,
            storeName: store.name,
            streetAddress: data.addressLine1,
            userEmail: user.email,
            userName: user.name,
            vin,
            yearMakeModel: `${year} ${make} ${model}`.trim(),
          });

        const variables = {
          data: body,
          from_alias: fromAlias,
          from,
          subject,
          to_alias: toAlias,
          to,
        };

        try {
          const res = await fetch(HasuraUrl, {
            method: 'POST',
            body: JSON.stringify({
              query: SendEmailGqlStr,
              variables,
            }),
          });

          if (res.status !== 200) {
            throw new Error(res.statusText);
          }

          const { error } = await updateInspectionRequest({
            variables: {
              id: vehicleId,
              inspection_request: true,
            },
          });
          if (error) {
            throw new error(error);
          }

          formik.handleReset();
          formik.setSubmitting(false);
          notify('Request sent', 'success');
          onConfirm?.();
        } catch (error) {
          notify('Failed to request inspection', 'error');
          console.error(error);
          formik.setSubmitting(false);
        }
      };

      send();
    },
  });

  const handleSave = async (_, { proceedToConfirmation }) => {
    formik.setTouched({
      addressLine1: true,
      city: true,
      state: true,
      zip: true,
      country: true,
    });

    const errors = await formik.validateForm();
    if (!Object.keys(errors).length) {
      proceedToConfirmation();
    }
  };

  const handleAddressChange = (...args) => {
    setPresetStore('');
    formik.handleChange(...args);
  };

  const renderStoreOptions = stores.data?.stores.map(({ id, name }) => (
    <MenuItem value={id} key={id}>
      <Box display="flex" flexDirection="column" width="100%">
        {name}
      </Box>
    </MenuItem>
  )) ?? <MenuItem>No Store Available</MenuItem>;

  const renderedConfirmContent = (
    <>
      <Typography>
        {Locale.VIN}: {vin}
      </Typography>
      <Typography>
        {Locale.RequestInspectionYearMakeModel}: {year} {make} {model} {style}{' '}
        {trim}
      </Typography>
      <Typography>
        {Locale.Street}: {formik.values.addressLine1}
      </Typography>
      <Typography>
        {Locale.City}: {formik.values.city}
      </Typography>
      <Typography>
        {Locale.State}: {formik.values.state}
      </Typography>
      <Typography>
        {Locale.PostalCode}: {formik.values.zip}
      </Typography>
      <Typography gutterBottom paragraph>
        {Locale.Country}: {formik.values.country}
      </Typography>
    </>
  );

  const isModalLoading =
    isLoading || vehicle.loading || stores.loading || formik.isSubmitting;

  return (
    <ModalAction
      confirmContent={renderedConfirmContent}
      confirmDescription={Locale.RequestInspectionConfirmDescription}
      isBulk
      isLoading={isModalLoading}
      isOpen={isOpen}
      isSaveDisabled={vehicle.loading}
      onClose={onClose}
      onConfirm={formik.handleSubmit}
      onSave={handleSave}
      saveButtonText={Locale.RequestInspectionSaveButtonText}
      saveDescription={Locale.RequestInspectionSaveDescription}
      saveTitle={Locale.RequestInspectionSaveTitle}
    >
      {vehicle.loading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography>
            {Locale.VIN}: {vin}
          </Typography>
          <Typography gutterBottom paragraph>
            {Locale.RequestInspectionYearMakeModel}: {year} {make} {model}{' '}
            {style} {trim}
          </Typography>
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel id="store-label">
              {Locale.RequestInspectionStore}
            </InputLabel>
            <Select
              id="store"
              labelId="store-label"
              onChange={(event) => setPresetStore(event.target.value)}
              value={renderStoreOptions.length ? presetStore : ''}
            >
              {renderStoreOptions}
            </Select>
          </FormControl>
          <TextField
            id="address-line-1"
            label={Locale.Street}
            name="addressLine1"
            variant="filled"
            onChange={handleAddressChange}
            value={formik.values.addressLine1}
            touched={formik.touched.addressLine1}
            error={formik.errors.addressLine1}
          />
          <TextField
            id="city"
            label={Locale.City}
            name="city"
            variant="filled"
            onChange={handleAddressChange}
            value={formik.values.city}
            touched={formik.touched.city}
            error={formik.errors.city}
          />
          <TextField
            id="state"
            label={Locale.State}
            name="state"
            variant="filled"
            onChange={handleAddressChange}
            value={formik.values.state}
            touched={formik.touched.state}
            error={formik.errors.state}
          />
          <TextField
            id="zip"
            label={Locale.PostalCode}
            name="zip"
            variant="filled"
            onChange={handleAddressChange}
            value={formik.values.zip}
            touched={formik.touched.zip}
            error={formik.errors.zip}
          />
          <TextField
            id="country"
            label={Locale.Country}
            name="country"
            variant="filled"
            onChange={handleAddressChange}
            value={formik.values.country}
            touched={formik.touched.country}
            error={formik.errors.country}
          />
        </>
      )}
    </ModalAction>
  );
};

export default ModalRequestInspection;

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    marginBottom: 20,
  },
}));
