import React from 'react';
import { useQuery } from '@apollo/client';
import {
  AutocompleteArrayInput,
  BooleanInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { ToolbarEdit } from '../ui';
import { Countries, StoreTags } from '../lib';
import { StoreCommunicationFindAllByStoreIdGql } from '../gql';
import StoreEditActions from './StoreEditActions';
import StoreCommunicationField from './StoreCommunicationField';

const CountryChoices = Countries.map(({ code, name }) => ({ id: code, name }));
const StoreTagChoices = StoreTags.map((store) => ({ id: store, name: store }));

const StoreEdit = ({ id, ...props }) => {
  const storeCommunication = useQuery(StoreCommunicationFindAllByStoreIdGql, {
    variables: { ids: [id] },
  });

  const storeCommunicationData = storeCommunication.data?.store_communication;
  const twilioPhone = storeCommunicationData?.[0]?.twilio_phone;

  return (
    <Edit
      {...props}
      id={id}
      actions={
        !twilioPhone &&
        !storeCommunication.loading && (
          <StoreEditActions
            onCreatePhoneSuccess={storeCommunication.refetch}
            id={id}
          />
        )
      }
    >
      <SimpleForm toolbar={<ToolbarEdit />}>
        <TextInput disabled source="id" />
        <TextInput source="name" />
        <AutocompleteArrayInput choices={StoreTagChoices} source="tags" />
        <TextInput source="type" />
        <SelectInput source="country" choices={CountryChoices} />
        <ReferenceInput source="area_id" reference="areas">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="appointment_target" />
        <StoreCommunicationField
          label="Phone"
          addLabel
          storeCommunication={storeCommunicationData}
        />
        <BooleanInput source="active" />
        <BooleanInput source="exclude_from_stats" />
      </SimpleForm>
    </Edit>
  );
};

export default StoreEdit;
