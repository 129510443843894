const CurrentYear = new Date().getFullYear();

export const Years = Array(50)
  .fill(0)
  .map((_, idx) => {
    const year = `${CurrentYear - idx}`;
    return { id: year, name: year };
  });

/**
 * Add days to a JS Date instance source
 *
 * @param {Date} dateObj - source
 * @param {number} days - number of days to add
 * @returns {Date} new Date instance with updated date
 */
export const addDays = (dateObj, days = 0) => {
  const newDateObj = new Date(dateObj.valueOf());
  newDateObj.setUTCDate(newDateObj.getUTCDate() + days);
  return newDateObj;
};
