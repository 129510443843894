import React, { useEffect, useState } from "react";
import buildHasuraProvider from "ra-data-hasura";
import { Admin, Resource, Loading } from "react-admin";
import { ApolloProvider } from "@apollo/client";
import Dashboard from "./dashboard/Dashboard";
import { AppraisalAdminResources, AppraisalUserResources } from "./Resources";

import { Roles } from "./roles";
import { history } from "./history";
import { createApolloClient } from "./client";
import { authProvider } from "./authProvider";
import { AppLogin } from "./main";

const App = () => {
  const [client, setClient] = useState({});
  const [dataProvider, setDataProvider] = useState(null);
  useEffect(() => {
    const buildDataProvider = async () => {
      const token = localStorage.getItem("token");
      const apolloClient = createApolloClient(token);
      const dataProvider = await buildHasuraProvider({ client: apolloClient });
      setClient(apolloClient);
      setDataProvider(() => dataProvider);
    };
    buildDataProvider();
  }, []);

  if (!dataProvider) {
    return (
      <Loading
        loadingPrimary="Loading"
        loadingSecondary="Connecting to data source"
      />
    );
  }

  const checkPerm = (requiredRole) => {
    return (userRole, comps) =>
      requiredRole === userRole.role ? [...comps] : null;
  };

  const isAppraisalAdmin = checkPerm(Roles.AppraisalAdmin);
  const isAppraisalUser = checkPerm(Roles.AppraisalUser);

  return (
    <ApolloProvider client={client}>
      <Admin
        authProvider={authProvider}
        dashboard={Dashboard}
        dataProvider={dataProvider}
        history={history}
        loginPage={AppLogin}
        title="Drivably Admin"
      >
        {(role) => [
          // Including contacts as resource here is a hack so we don't get infinite loading if the user role doesn't fit any of the defined here
          <Resource name="contacts" />,
          isAppraisalUser(role, AppraisalUserResources),
          isAppraisalAdmin(role, AppraisalAdminResources),
        ]}
      </Admin>
    </ApolloProvider>
  );
};

export default App;
