import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import {
  DateField,
  EmailField,
  FunctionField,
  List,
  TextField,
  TextInput,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';

import { UserRoleFindAllByUserIdGql } from '../gql';
import { Datagrid, Pagination } from '../ui';
import UserListActions from './UserListActions';

const UserList = (props) => {
  const classes = useStyles();
  const [userIds, setUserIds] = useState([]);
  const { data } = useQuery(UserRoleFindAllByUserIdGql, {
    variables: { ids: userIds },
  });

  const filters = [
    <TextInput alwaysOn label="Search" source="email,mobile,name" />,
  ];

  const userRolesData = data?.user_roles ?? [];

  return (
    <List
      {...props}
      bulkActionButtons={<UserListActions />}
      filters={filters}
      pagination={<Pagination />}
      sort={{
        field: 'email',
        order: 'ASC',
      }}
    >
      <Datagrid onPageLoad={setUserIds} rowClick="show">
        <EmailField source="email" />
        <TextField source="name" />
        <TextField source="mobile" />
        <DateField showTime source="created_at" />
        <DateField showTime source="updated_at" />
        <FunctionField
          label="Stores"
          render={({ id }) => {
            const storesCache = {};
            const renderedStores = [];

            userRolesData.forEach(({ user_id, store }) => {
              if (user_id === id && !storesCache[store.name]) {
                storesCache[store.name] = true;
                renderedStores.push(
                  <Chip key={store.name} size="small" label={store.name} />,
                );
              }
            });

            return renderedStores;
          }}
        />
        <FunctionField
          label="Roles"
          render={({ id }) => {
            const rolesCache = {};
            const renderedStores = [];

            userRolesData.forEach(({ user_id, role }) => {
              if (user_id === id && !rolesCache[role]) {
                rolesCache[role] = true;
                renderedStores.push(
                  <Chip
                    className={classes.chipRole}
                    key={role}
                    label={role.replace(/-/g, ' ')}
                    size="small"
                  />,
                );
              }
            });

            return renderedStores;
          }}
        />
      </Datagrid>
    </List>
  );
};

export default UserList;

const useStyles = makeStyles(() => ({
  chipRole: {
    textTransform: 'capitalize',
  },
}));
