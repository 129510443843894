import {
  SimpleForm,
  BooleanInput,
  Create,
  SelectInput,
  ReferenceInput,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";

const AppraiserAdminUserCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`User added`);
    redirect("/users");
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <BooleanInput source="access_granted" />
        {props.options.showOffer && <BooleanInput source="offer_visible" />}
        <ReferenceInput source="user_id" reference="users">
          <SelectInput source="email" />
        </ReferenceInput>
        <ReferenceInput source="store_id" reference="stores">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default AppraiserAdminUserCreate;
