import { gql } from '@apollo/client';

export const MessageByUserIDCreatedAtGql = gql`
  query MessageByUserIDCreatedAt(
    $user_id: Int!
    $created_at_from: timestamptz!
    $created_at_to: timestamptz!
  ) {
    messages(
      where: {
        _and: [
          { user_id: { _eq: $user_id } }
          { created_at: { _gte: $created_at_from } }
          { created_at: { _lt: $created_at_to } }
        ]
        type: { _is_null: true }
      }
      order_by: { created_at: desc }
    ) {
      content
      created_at
      type
      user_id
    }
  }
`;
