import React from 'react';
import {
  BooleanInput,
  Create,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

const VehicleCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="year" />
      <TextInput source="make" />
      <TextInput source="model" />
      <TextInput source="style" />
      <TextInput source="trim" />
      <NumberInput source="mi" />
      <NumberInput source="km" />
      <TextInput source="source_link" />
      <TextInput source="source_platform" />
      <NumberInput source="adjusted_whole_avg" />
      <NumberInput source="adjusted_whole_clean" />
      <NumberInput source="asking_price" />
      <NumberInput source="purchase_price" />
      <ReferenceInput source="contact_id" reference="contacts">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="store_id" reference="stores">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="title" />
      <TextInput multiline source="description" />
      <TextInput source="last_message" />
      <TextInput multiline source="notes" />
      <DateInput source="posted_at" />
      <BooleanInput source="acquired" />
      <BooleanInput source="sent_to_crm" />
    </SimpleForm>
  </Create>
);

export default VehicleCreate;
