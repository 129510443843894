import React from "react";
import {
  Show,
  SimpleShowLayout,
  NumberField,
  TextField,
  FunctionField,
  DateField,
  Loading,
  usePermissions,
} from "react-admin";
import OfferField from "./OfferField";
import { useQuery } from "@apollo/client";
import { STORE_PERM } from "./graphql";

const AppraiserVehicleShow = (props) => {
  const { loading, data: storePerm } = useQuery(STORE_PERM);
  const { loaded, permissions } = usePermissions();

  if (loading) return <Loading />;

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <FunctionField
          label="Basic Info"
          render={(x) => `${x.year} ${x.make} ${x.model}`}
        />
        ,
        <NumberField label="Odometer" source="mi" />,
        <TextField source="style" />,
        <TextField source="trim" />,
        <OfferField
          addLabel
          role={loaded && permissions.role}
          offerPerm={storePerm}
          source="offer"
        />
        ,
        <NumberField source="adjusted_whole_avg" />,
        <NumberField source="adjusted_whole_clean" />,
        <NumberField source="adjusted_whole_rough" />,
        <NumberField source="adjusted_whole_xclean" />,
        <TextField source="vin" />,
        <DateField source="created_at" />
        <TextField
          source="extra.disclosure_answers.body_damage"
          label="Body damage"
        />
        ,
        <TextField
          source="extra.disclosure_answers.body_damage_severity"
          label="Body damage severity"
        />
        ,
        <TextField
          source="extra.disclosure_answers.engine_issues"
          label="Engine issues"
        />
        ,
        <TextField
          source="extra.disclosure_answers.engine_issues_types"
          label="Engine issues types"
        />
        ,
        <TextField
          source="extra.disclosure_answers.frame_damage"
          label="Frame damage"
        />
        ,
        <TextField
          source="extra.disclosure_answers.interior_issue_types"
          label="Interior issue types"
        />
        ,
        <TextField
          source="extra.disclosure_answers.interior_issues"
          label="Interior issues"
        />
        ,
        <TextField
          source="extra.disclosure_answers.mechanical_issue_types"
          label="Mechanical issue types"
        />
        ,
        <TextField
          source="extra.disclosure_answers.mechanical_issues"
          label="Mechanical issues"
        />
        ,
        <TextField
          source="extra.disclosure_answers.modifications"
          label="Modifications"
        />
        ,
        <TextField
          source="extra.disclosure_answers.modifications_types"
          label="Modifications types"
        />
        ,
        <TextField
          source="extra.disclosure_answers.option_equipment"
          label="Option equipment"
        />
        ,
        <TextField
          source="extra.disclosure_answers.rust_damage"
          label="Rust damage"
        />
        ,
        <TextField
          source="extra.disclosure_answers.rust_damage_severity"
          label="Rust damage severity"
        />
        ,
        <TextField
          source="extra.disclosure_answers.tire_issues"
          label="Tire issues"
        />
        ,
        <TextField
          source="extra.disclosure_answers.tire_issues_types"
          label="Tire issues types"
        />
        ,
        <TextField
          source="extra.disclosure_answers.tire_tread"
          label="Tire tread"
        />
        ,
        <TextField
          source="extra.disclosure_answers.title_branded"
          label="Title branded"
        />
        ,
        <TextField
          source="extra.disclosure_answers.title_present"
          label="Title present"
        />
        ,
        <TextField
          source="extra.disclosure_answers.transmission"
          label="Transmission"
        />
        ,
        <TextField
          source="extra.disclosure_answers.warning_lights"
          label="Warning lights"
        />
        ,
        <TextField
          source="extra.disclosure_answers.warning_lights_types"
          label="Warning lights types"
        />
        ,
      </SimpleShowLayout>
    </Show>
  );
};

export default AppraiserVehicleShow;
