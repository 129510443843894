import { createApolloClient } from "./client";
import { AuthLoginGql } from "./gql";
import { getRole, getUserID } from "./roles";

/**
 * Auth provider for RA Admin component
 *
 * @param {string} type - Dispatched RA auth event
 * @param {object|null} params - Contains username, password and API response
 * @returns {Promise} Rejected Promise means invalid auth operation (login, etc)
 */
export const authProvider = {
  // Handle login action
  login: async ({ username, password }) => {
    const client = createApolloClient();
    const { data, error } = await client.query({
      query: AuthLoginGql,
      variables: { email: username, password },
    });
    if (error) {
      return Promise.reject(error);
    }
    localStorage.setItem("token", data.login.token);
    localStorage.setItem("role", getRole(data.login.token));
    localStorage.setItem("userID", getUserID(data.login.token));
    return Promise.resolve({ redirectTo: "/vehicles" });
  },
  // Handle logout action

  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("userID");
    return Promise.resolve();
  },

  // Handle authentication api errors
  checkError: (e) => {
    if (e.status === 401 || e.status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("userID");
      return Promise.reject({ message: false });
    }

    return Promise.resolve();
  },
  // Per route authentication ping
  checkAuth: () => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    if (role && token) {
      return Promise.resolve({ redirectTo: "/vehicles" });
    }

    return Promise.reject("Session expired. Please login to continue.");
  },
  getPermissions: () => {
    const role = localStorage.getItem("role");
    const userID = localStorage.getItem("userID");
    return role
      ? Promise.resolve({ role: role, userID: userID })
      : Promise.reject();
  },
};
