import {
  Edit,
  SimpleForm,
  ReferenceManyField,
  SingleFieldList,
  useReference,
  useRecordContext,
  LinearProgress,
} from "react-admin";
import Chip from "@material-ui/core/Chip";

const StoreChips = (props) => {
  const record = useRecordContext(props);
  const { loading, referenceRecord } = useReference({
    id: record.store_id,
    reference: "stores",
  });

  if (loading) return <LinearProgress />;
  if (!referenceRecord) return null;
  return (
    <Chip
      clickable
      color={record?.access_granted ? "primary" : ""}
      style={{ margin: "0.3rem" }}
      label={referenceRecord.name}
    />
  );
};

const PostTitle = ({ record }) => {
  return <span>User {record ? `${record.name}` : ""}</span>;
};

const UserEdit = (props) => {
  return (
    <Edit title={<PostTitle />} {...props}>
      <SimpleForm toolbar={<></>}>
        <ReferenceManyField
          label="Appraiser in:"
          reference="appraisal_user_stores"
          target="user_id"
          emptyText="None"
        >
          <SingleFieldList emptyText="none">
            <StoreChips {...props} />
          </SingleFieldList>
        </ReferenceManyField>
        <ReferenceManyField
          label="Admin in:"
          reference="appraisal_admin_stores"
          target="user_id"
        >
          <SingleFieldList>
            <StoreChips {...props} />
          </SingleFieldList>
        </ReferenceManyField>
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
