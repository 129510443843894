import React from 'react';
import {
  BooleanInput,
  Create,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { Countries } from '../lib';

const CountryChoices = Countries.map(({ code, name }) => ({ id: code, name }));

const StoreCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="tags" />
      <TextInput source="type" />
      <SelectInput source="country" choices={CountryChoices} />
      <ReferenceInput source="area_id" reference="areas">
          <SelectInput optionText="name" />
        </ReferenceInput>
      <NumberInput source="appointment_target" />
      <BooleanInput source="active" />
      <BooleanInput source="exclude_from_stats" />
    </SimpleForm>
  </Create>
);

export default StoreCreate;
