import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const UserMessageField = ({ messages = [] }) => {
  const renderedHeaders = [
    <TableCell key="th-content" component="th">
      Content
    </TableCell>,
    <TableCell key="th-media" component="th">
      Media
    </TableCell>,
    <TableCell key="th-created-at" component="th">
      Created At
    </TableCell>,
  ];

  const renderMessages = () => {
    if (!messages.length) {
      return (
        <TableRow>
          <TableCell colSpan={renderedHeaders.length}>
            No Messages Sent
          </TableCell>
        </TableRow>
      );
    }

    messages.map(({ content, created_at }, index) => {
      const contentJSON = JSON.parse(content);

      return (
        <TableRow key={index}>
          <TableCell>{contentJSON.message}</TableCell>
          <TableCell>
            {contentJSON.media ? (
              <img src={contentJSON.media} alt="message-media" />
            ) : null}
          </TableCell>
          <TableCell>{new Date(created_at).toDateString()}</TableCell>
        </TableRow>
      );
    });
  };

  return (
    <Table aria-label="User Message">
      <TableHead>
        <TableRow>{renderedHeaders}</TableRow>
      </TableHead>
      <TableBody>{renderMessages()}</TableBody>
    </Table>
  );
};

export default UserMessageField;
