import {
  SimpleForm,
  BooleanInput,
  ReferenceInput,
  Edit,
  SelectInput,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";

// show delete button only on appraisal users
const CustomToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
      {props.options.showOffer && <DeleteButton style={{ float: "right" }} />}
    </Toolbar>
  );
};

const AppraiserAdminUserEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar {...props} />}>
        <BooleanInput source="access_granted" />
        {props.options.showOffer && <BooleanInput source="offer_visible" />}
        <ReferenceInput source="store_id" reference="stores">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default AppraiserAdminUserEdit;
