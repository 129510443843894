import * as React from "react";
import { useRecordContext, NumberField } from "react-admin";
import { Roles } from "../../roles";

const OfferField = (props) => {
  const record = useRecordContext(props);
  let show = false;
  for (let s of props.offerPerm.appraisal_user_stores) {
    if (s.store_id === record.store_id && s.offer_visible) {
      show = true;
    }
  }
  if (props.role === Roles.AppraisalAdmin) {
    show = true;
  }

  return show ? (
    <NumberField {...props} options={{ style: "currency", currency: "USD" }} />
  ) : null;
};

export default OfferField;
