import { gql } from '@apollo/client';

export const UserRoleFindAllByUserIdGql = gql`
  query UserRoleFindAllByUserId($ids: [Int!]) {
    user_roles(where: { user_id: { _in: $ids } }) {
      user_id
      store_id
      store {
        name
        id
      }
      role
    }
  }
`;

export const UserRoleFindAllByStoreIdGql = gql`
  query UserRoleFindAllByStoreId($ids: [Int!]) {
    user_roles(where: { store_id: { _in: $ids } }) {
      user_id
      user {
        id
        name
        email
      }
      store_id
    }
  }
`;
