import { Resource } from "ra-core";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import { VerifiedUserSharp } from "@material-ui/icons";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ContactsIcon from "@material-ui/icons/Contacts";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import StoreIcon from "@material-ui/icons/Store";
import { AccountList, AccountEdit, AccountCreate } from "./account";
import { ContactList, ContactEdit, ContactCreate } from "./contact";
import { StoreList, StoreEdit, StoreCreate } from "./store";
import { VehicleList, VehicleEdit, VehicleCreate } from "./vehicle";
import { UserList, UserEdit, UserCreate, UserShow } from "./user";
import AppraiserVehicleShow from "./vehicle/appraisal/Show";
import AppraiserVehicleList from "./vehicle/appraisal/List";
import AppraiserAdminUserEdit from "./appraisalUser/admin/Edit";
import AppraiserAdminUserCreate from "./appraisalUser/admin/Create";
import UserIcon from "@material-ui/icons/People";
import { UserList as AppraisalUserList } from "./user/appraisal/List";
import AppraisalUserEdit from "./user/appraisal/Edit";

export const AppraisalUserResources = [
  <Resource
    name="vehicles"
    options={{ label: "Appraised" }}
    list={AppraiserVehicleList}
    icon={DriveEtaIcon}
    show={AppraiserVehicleShow}
  />,
  <Resource name="contacts" />,
  <Resource name="stores" />,
  <Resource name="users" />,
];

export const AppraisalAdminResources = [
  <Resource
    name="vehicles"
    options={{ label: "Vehicles" }}
    list={AppraiserVehicleList}
    show={AppraiserVehicleShow}
    icon={DriveEtaIcon}
  />,
  <Resource name="contacts" />,
  // <Resource name="stores" edit={EditGuesser} list={ListGuesser} />,
  <Resource name="stores" />,
  <Resource name="users" list={AppraisalUserList} edit={AppraisalUserEdit} />,
  <Resource
    options={{ label: "Appraisers", showOffer: true }}
    name="appraisal_user_stores"
    icon={UserIcon}
    edit={AppraiserAdminUserEdit}
    // list={AppraiserAdminUserList}
    create={AppraiserAdminUserCreate}
  />,
  <Resource
    options={{ label: "Admins" }}
    name="appraisal_admin_stores"
    icon={VerifiedUserSharp}
    edit={AppraiserAdminUserEdit}
    // list={AppraiserAdminUserList}
    create={AppraiserAdminUserCreate}
  />,
];

export const DrivablyAdminResources = [
  <Resource
    create={AccountCreate}
    edit={AccountEdit}
    icon={AccountBoxIcon}
    list={AccountList}
    name="accounts"
  />,
  <Resource
    create={ContactCreate}
    edit={ContactEdit}
    icon={ContactsIcon}
    list={ContactList}
    name="contacts"
  />,
  <Resource
    create={StoreCreate}
    edit={StoreEdit}
    icon={StoreIcon}
    list={StoreList}
    name="stores"
  />,
  <Resource
    name="vehicles"
    list={VehicleList}
    icon={DriveEtaIcon}
    edit={VehicleEdit}
    create={VehicleCreate}
  />,
  <Resource
    name="users"
    list={UserList}
    icon={PeopleAltIcon}
    edit={UserEdit}
    create={UserCreate}
    show={UserShow}
  />,
  <Resource name="areas" />,
  <Resource name="stores" />,
];
