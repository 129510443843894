import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import { getStatusListFromObject } from './lib';

const VehicleStatusChips = ({ id, vehicleStatus = [] }) => {
  const classes = useStyles();

  const currentVehicleStatus = vehicleStatus.find(
    (item) => item.vehicle_id === id,
  );
  if (currentVehicleStatus) {
    const list = getStatusListFromObject(currentVehicleStatus);

    return list.map((item) => (
      <Chip
        className={classes.chip}
        key={item}
        label={item.replace(/_/g, ' ')}
        size="small"
      />
    ));
  }

  return null;
};

export default VehicleStatusChips;

const useStyles = makeStyles(() => ({
  chip: {
    textTransform: 'capitalize',
  },
}));
