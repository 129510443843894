import React from 'react';

import StoreEditPause from './StoreEditPause';

const StoreListActions = ({ onPauseUpdateSuccess, ...props }) => (
  <>
    <StoreEditPause {...props} onSuccess={onPauseUpdateSuccess} />
  </>
);

export default StoreListActions;
