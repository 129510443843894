import { gql } from '@apollo/client';

export const StoreCommunicationFindAllByStoreIdGql = gql`
  query StoreCommunicationFindAllByStoreId($ids: [Int!]) {
    store_communication(where: { store_id: { _in: $ids } }) {
      store_id
      twilio_phone
    }
  }
`;

export const StoreCommunicationCreateGql = gql`
  mutation StoreCommunicationCreate($storeId: Int!, $phoneNumber: String!) {
    insert_store_communication_one(
      object: { store_id: $storeId, twilio_phone: $phoneNumber }
    ) {
      store_id
      twilio_phone
    }
  }
`;
