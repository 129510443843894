import React from 'react';
import { Button as ButtonRA } from 'react-admin';
import StoreIcon from '@material-ui/icons/Store';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { ModalConfirm } from '../ui';
import { useDeleteMany, useToggle } from '../hooks';

const UserDelete = ({ selectedIds }) => {
  const classes = useStyles();
  const [isOpen, toggleIsOpen] = useToggle(false);

  const [deleteUsers, { loading }] = useDeleteMany('users', selectedIds, {
    onSuccess: toggleIsOpen,
  });

  return (
    <>
      <ButtonRA
        label="Delete"
        className={classes.deleteButton}
        onClick={toggleIsOpen}
      >
        <StoreIcon />
      </ButtonRA>
      <ModalConfirm
        description={`Are you sure you want to delete ${selectedIds.length} user(s)?`}
        isLoading={loading}
        isOpen={isOpen}
        onClose={toggleIsOpen}
        onConfirm={deleteUsers}
        title="Delete Users"
      />
    </>
  );
};

export default UserDelete;

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));
