import React from 'react';
import { useQuery } from '@apollo/client';
import {
  BooleanInput,
  DateInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import {
  VehicleFindAllStoreTagsByIdGql,
  VehicleStatusFindAllByVehicleIdGql,
} from '../gql';
import VehicleEditActions from './VehicleEditActions';
import VehicleStatusField from './VehicleStatusField';
import { getStatusListFromObject } from './lib';

export const VehicleEdit = ({ id, ...props }) => {
  const vehicleStatus = useQuery(VehicleStatusFindAllByVehicleIdGql, {
    variables: { ids: [id] },
  });

  const vehicle = useQuery(VehicleFindAllStoreTagsByIdGql, {
    variables: { id: Number(id) },
  });

  const tagChoices = vehicle.data?.vehicles[0].store.tags.map((tag) => ({
    id: tag,
    name: tag,
  }));

  const currentStatus = getStatusListFromObject(
    vehicleStatus.data?.vehicle_status?.[0],
  );

  return (
    <Edit
      {...props}
      id={id}
      actions={
        <VehicleEditActions
          id={id}
          onEditStatusSuccess={vehicleStatus.refetch}
          status={currentStatus}
        />
      }
    >
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput source="year" />
        <TextInput source="make" />
        <TextInput source="model" />
        <TextInput source="style" />
        <TextInput source="trim" />
        <VehicleStatusField
          label="Status"
          addLabel
          vehicleStatus={vehicleStatus.data?.vehicle_status}
        />
        <NumberInput source="km" />
        <TextInput source="source_platform" />
        <NumberInput source="adjusted_whole_avg" />
        <NumberInput source="adjusted_whole_clean" />
        <NumberInput source="asking_price" />
        <NumberInput source="purchase_price" />
        <ReferenceInput source="contact_id" reference="contacts">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="store_id" reference="stores">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput source="posted_at" />
        <BooleanInput source="acquired" />
        <BooleanInput source="sent_to_crm" />
        <SelectArrayInput label="Tags" source="tags" choices={tagChoices} />
      </SimpleForm>
    </Edit>
  );
};

export default VehicleEdit;
