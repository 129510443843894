import React from 'react';
import { DatagridBody as DatagridBodyRA } from 'react-admin';

import DatagridRow from './DatagridRow';

const DatagridBody = (props) => (
  <DatagridBodyRA {...props} row={<DatagridRow />} />
);

export default DatagridBody;
