import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { HasuraUrl } from './lib';

const httpLink = (token) =>
  new HttpLink({
    uri: HasuraUrl,
    headers: { ...(token && { Authorization: `Bearer ${token}` }) },
  });

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.extensions.code === 'invalid-jwt') {
        localStorage.removeItem('token');
        window.location.replace('/login');
      }
    }
  }
});

export const createApolloClient = (token) =>
  new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, httpLink(token)]),
  });
