import { gql } from "@apollo/client";

export const STORE_PERM = gql`
  {
    appraisal_user_stores {
      user_id
      store_id
      store_id
      offer_visible
    }
  }
`;
