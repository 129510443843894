import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  DateField,
  List,
  NumberField,
  FunctionField,
  ReferenceField,
  Loading,
  TextField,
  TextInput,
  usePermissions,
  useRefresh,
} from 'react-admin';
import Locale from '../../locale/en';
import { VehicleStatusFindAllByVehicleIdGql } from '../../gql';
import { Datagrid, Pagination } from '../../ui';
import { makeStyles, Button } from '@material-ui/core';
import OfferField from './OfferField';
import { STORE_PERM } from './graphql';
import { Roles } from '../../roles';
import ModalRequestInspection from './ModalRequestInspection';

const VehicleList = (props) => {
  const useStyles = makeStyles({
    row: {
      height: '5vh',
    },
  });
  const classes = useStyles();
  const refresh = useRefresh();

  const [inspectionVehicleId, setInspectionVehicleId] = useState(null);

  const [vehicleIds, setVehicleIds] = useState([]);
  const { data } = useQuery(VehicleStatusFindAllByVehicleIdGql, {
    variables: { ids: vehicleIds },
  });

  const { data: storePerm } = useQuery(STORE_PERM);
  const { permissions, loaded } = usePermissions();

  if (!loaded) return <Loading />;

  const clearInspectionVehicle = () => setInspectionVehicleId(0);

  const handleRequestInspectionConfirm = () => {
    clearInspectionVehicle();
    refresh();
  };

  const showOfferColumn =
    storePerm?.appraisal_user_stores.filter((s) => s.offer_visible).length >
      0 || permissions.role === Roles.AppraisalAdmin;

  const filters = [
    <TextInput
      alwaysOn
      label="Search"
      source="vin,make,model,trim,style,tags"
    />,
    <TextInput
      alwaysOn
      label="Rep"
      source="contact#name@_ilike,contact#email@_ilike,user#name@_ilike,user#email@_ilike"
    />,
    <TextInput alwaysOn label="Store" source="store#name@_ilike" />,
  ];

  const vehicleStatusData = data?.vehicle_status;
  console.log(vehicleStatusData);

  return (
    <>
      <List
        {...props}
        // bulkActionButtons={<VehicleListActions onStatusUpdateSuccess={refetch} />}
        bulkActionButtons={false}
        filters={filters}
        pagination={<Pagination />}
        sort={{
          field: 'created_at',
          order: 'DESC',
        }}
      >
        <Datagrid
          classes={{ row: classes.row }}
          onPageLoad={setVehicleIds}
          rowClick="show"
        >
          <TextField source="vin" />
          <TextField source="year" />
          <TextField source="make" />
          <TextField source="model" />
          <TextField source="style" />
          <TextField source="trim" />
          <NumberField source="mi" />
          {showOfferColumn ? (
            <OfferField
              role={loaded && permissions.role}
              offerPerm={storePerm}
              source="offer"
            />
          ) : (
            <></>
          )}
          <ReferenceField
            label="Store"
            source="store_id"
            reference="stores"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Appraiser Name"
            source="appraiser_id"
            reference="users"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Appraiser Email"
            source="appraiser_id"
            reference="users"
            link={false}
          >
            <TextField source="email" />
          </ReferenceField>
          {/* <ReferenceField source="store_id" reference="stores">
          <TextField source="name" />
        </ReferenceField> */}
          <DateField source="created_at" />
          {/* <TextField source="source_platform" /> */}
          {/* <FunctionField
          label="Tags"
          render={({ tags }) => <ChipTags tags={tags} />}
        />
        <FunctionField
          label="Status"
          render={({ id }) => (
            <VehicleStatusChips id={id} vehicleStatus={vehicleStatusData} />
          )}
        /> */}
          <FunctionField
            label="Action"
            render={({ id, inspection_request }) =>
              !inspection_request && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    e.stopPropagation();
                    setInspectionVehicleId(id);
                  }}
                >
                  {Locale.RequestInspectionButton}
                </Button>
              )
            }
          />
        </Datagrid>
      </List>
      <ModalRequestInspection
        isOpen={Boolean(inspectionVehicleId)}
        vehicleId={inspectionVehicleId}
        onClose={clearInspectionVehicle}
        onConfirm={handleRequestInspectionConfirm}
      />
    </>
  );
};

export default VehicleList;
