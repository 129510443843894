import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const ContactCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="mobile" />
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

export default ContactCreate;
