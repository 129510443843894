import React from 'react';
import cls from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { TextField as TextFieldMU } from '@material-ui/core';

const TextField = ({ touched, error, className, ...props }) => {
  const classes = useStyles();
  const classNames = cls(className, classes.formControl);

  return (
    <>
      <TextFieldMU
        {...props}
        error={Boolean(error)}
        helperText={touched && error ? error : ''}
        className={classNames}
      />
    </>
  );
};

export default TextField;

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    marginBottom: 20,
  },
}));
