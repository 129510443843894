import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from '@apollo/client';
import { Button as ButtonRA, useNotify, useRefresh } from 'react-admin';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import ChatIcon from '@material-ui/icons/Chat';
import ImageIcon from '@material-ui/icons/Image';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import {
  Box,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
  Tooltip,
} from '@material-ui/core';

import { ModalAction } from '../ui';
import { USStates, Country } from '../lib';
import {
  ActionCreatePhoneNumberGql,
  ActionGetPhoneNumbersGql,
  StoreCommunicationCreateGql,
} from '../gql';
import { useToggle } from '../hooks';

const StoreEditPhone = ({ onSuccess, id, store = {} }) => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [usState, setUSState] = useState('');
  const [city, setCity] = useState('');
  const [areaCode, setAreaCode] = useState('');

  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const storeName = `${store.name} (ID ${store.id})`;

  const phoneNumbers = useQuery(ActionGetPhoneNumbersGql, {
    variables: {
      countryCode: store.country,
      ...(areaCode && { areaCode: parseInt(areaCode) }),
      ...(city && { city }),
      ...(usState && { state: usState }),
    },
    skip: !store.country,
  });

  const [createStoreCommunication, { loading: isLoadingStoreCommunication }] =
    useMutation(StoreCommunicationCreateGql, {
      onCompleted: () => {
        notify(`${phoneNumber} assigned to store ${storeName}`);
        toggleIsOpen();
        refresh();
        onSuccess?.();
      },
      onError: () => notify('Failed to assign phone to store.', 'warning'),
    });

  const [createPhoneNumber, { loading: isLoadingStoreNumber }] = useMutation(
    ActionCreatePhoneNumberGql,
    {
      onCompleted: () =>
        createStoreCommunication({
          variables: {
            phoneNumber,
            storeId: id,
          },
        }),
      onError: () =>
        notify(
          `Failed to create phone number ${phoneNumber}. Please try again later.`,
          'warning',
        ),
    },
  );

  const confirmDescription = `Are you sure you want provision ${phoneNumber} for store ${storeName}?`;

  const renderedStateOptions = USStates.map(({ code, name }) => (
    <MenuItem key={code} value={code}>
      {name}
    </MenuItem>
  ));

  const renderCityOptions = () => {
    const currentState = USStates.find((s) => s.code === usState);

    if (currentState) {
      const list = currentState.cities.map((city) => (
        <MenuItem key={city} value={city}>
          {city}
        </MenuItem>
      ));

      if (city) {
        list.unshift(
          <MenuItem key="blank" value="">
            Reset City
          </MenuItem>,
        );
      }

      return list;
    }

    return [
      <MenuItem key="blank" value="">
        No City
      </MenuItem>,
    ];
  };

  const renderAreaCodeOptions = () => {
    const currentState = USStates.find((s) => s.code === usState);

    if (currentState) {
      const list = currentState.areaCodes.map((areaCode) => (
        <MenuItem key={areaCode} value={areaCode}>
          {areaCode}
        </MenuItem>
      ));

      if (areaCode) {
        list.unshift(
          <MenuItem key="blank" value="">
            Reset Area Code
          </MenuItem>,
        );
      }

      return list;
    }

    return [
      <MenuItem key="blank" value="">
        No Area Code
      </MenuItem>,
    ];
  };

  const renderedPhoneNumberOptions = phoneNumbers.data?.getPhoneNumbers.map(
    ({
      city,
      country_code,
      formatted_phone_number,
      has_mms,
      has_sms,
      has_voice,
      phone_number,
      state,
    }) => (
      <MenuItem value={phone_number} key={phone_number}>
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex">
            {formatted_phone_number}
            <Box display="flex" marginLeft="auto">
              {has_sms && (
                <Tooltip title="SMS Supported">
                  <ChatIcon className={classes.phoneIcon} />
                </Tooltip>
              )}
              {has_mms && (
                <Tooltip title="MMS Supported">
                  <ImageIcon className={classes.phoneIcon} />
                </Tooltip>
              )}
              {has_voice && (
                <Tooltip title="Voice Supported">
                  <PhoneInTalkIcon className={classes.phoneIcon} />
                </Tooltip>
              )}
            </Box>
          </Box>
          <Box display="flex">
            <Typography className={classes.phoneLabel} variant="body2">
              {city}, {state}, {country_code}
            </Typography>
          </Box>
        </Box>
      </MenuItem>
    ),
  ) ?? <MenuItem>No Phone Number Available</MenuItem>;

  return (
    <>
      <ButtonRA label="Provision Phone Number" onClick={toggleIsOpen}>
        <PhoneAndroidIcon />
      </ButtonRA>
      <ModalAction
        confirmDescription={confirmDescription}
        isBulk
        isLoading={isLoadingStoreNumber || isLoadingStoreCommunication}
        isOpen={isOpen}
        onClose={toggleIsOpen}
        onConfirm={() => createPhoneNumber({ variables: { phoneNumber } })}
        saveDescription={`Select new phone number for store ${storeName}.`}
        saveTitle="Provision Phone Number"
        saveButtonText="Provision"
      >
        <>
          <FormControl variant="filled" className={classes.formControlPhone}>
            <InputLabel id="phone-number-select-label">Phone Number</InputLabel>
            <Select
              id="phone-number-select"
              labelId="phone-number-select-label"
              onChange={(event) => setPhoneNumber(event.target.value)}
              value={phoneNumber}
            >
              {renderedPhoneNumberOptions}
            </Select>
          </FormControl>
          {phoneNumbers.loading && <LinearProgress />}
        </>
        {store.country === Country.US && (
          <>
            <Typography
              className={classes.options}
              variant="subtitle2"
              gutterBottom
            >
              Options
            </Typography>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel id="usState-select-label">State</InputLabel>
              <Select
                id="usState-select"
                labelId="usState-select-label"
                onChange={(event) => {
                  setPhoneNumber('');
                  setCity('');
                  setAreaCode('');
                  setUSState(event.target.value);
                }}
                value={usState}
              >
                <MenuItem key="blank" value="">
                  Reset State
                </MenuItem>
                {renderedStateOptions}
              </Select>
            </FormControl>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel id="city-select-label">City</InputLabel>
              <Select
                id="city-select"
                labelId="city-select-label"
                onChange={(event) => {
                  setPhoneNumber('');
                  setCity(event.target.value);
                }}
                value={city}
              >
                {renderCityOptions()}
              </Select>
            </FormControl>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel id="area-code-select-label">Area Code</InputLabel>
              <Select
                id="area-code-select"
                labelId="area-code-select-label"
                onChange={(event) => {
                  setPhoneNumber('');
                  setAreaCode(event.target.value);
                }}
                value={areaCode}
              >
                {renderAreaCodeOptions()}
              </Select>
            </FormControl>
          </>
        )}
      </ModalAction>
    </>
  );
};

export default StoreEditPhone;

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    marginBottom: 20,
  },
  formControlPhone: {
    width: '100%',
  },
  options: {
    marginTop: 20,
  },
  phoneIcon: {
    color: '#aaa',
    marginLeft: 5,
    marginRight: 5,
    fontSize: '1rem',
  },
  phoneLabel: {
    color: '#aaa',
  },
}));
