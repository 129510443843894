import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  BooleanField,
  BooleanInput,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import {
  UserRoleFindAllByStoreIdGql,
  StoreBotOptionFindAllByStoreIdGql,
  StoreCommunicationFindAllByStoreIdGql,
} from '../gql';
import { Datagrid, Pagination, ChipTags } from '../ui';
import StoreListActions from './StoreListActions';

const CountryOptions = [
  { id: 'US', name: 'US' },
  { id: 'CA', name: 'CA' },
];

const StoreList = (props) => {
  const [storeIds, setStoreIds] = useState([]);
  const userRoles = useQuery(UserRoleFindAllByStoreIdGql, {
    variables: { ids: storeIds },
  });

  const storeBotOptions = useQuery(StoreBotOptionFindAllByStoreIdGql, {
    variables: { ids: storeIds },
  });

  const storeCommunication = useQuery(StoreCommunicationFindAllByStoreIdGql, {
    variables: { ids: storeIds },
  });

  const filters = [
    <TextInput alwaysOn label="Search" source="name,tags" />,
    <SelectInput
      alwaysOn
      source="country"
      choices={CountryOptions}
      resettable
    />,
    <ReferenceInput
      alwaysOn
      label="Account"
      source="account_id"
      reference="accounts"
    >
      <SelectInput optionText="name" resettable />
    </ReferenceInput>,
    <BooleanInput source="active" />,
    <BooleanInput source="exclude_from_stats" />,
  ];

  const storeBotOptionsData = storeBotOptions.data?.store_bot_option ?? [];
  const storeCommunicationData =
    storeCommunication.data?.store_communication ?? [];
  const userRolesData = userRoles.data?.user_roles ?? [];

  return (
    <List
      {...props}
      bulkActionButtons={
        <StoreListActions onPauseUpdateSuccess={storeBotOptions.refetch} />
      }
      filters={filters}
      pagination={<Pagination />}
      sort={{
        field: 'name',
        order: 'ASC',
      }}
    >
      <Datagrid onPageLoad={setStoreIds} rowClick="edit">
        <TextField source="name" />
        <TextField source="country" />
        <TextField source="type" />
        <ReferenceField source="account_id" reference="accounts">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="appointment_target" />
        <BooleanField source="active" />
        <FunctionField
          label="Paused"
          render={({ id }) => {
            for (const option of storeBotOptionsData) {
              if (option.store_id === id) {
                return option.pause ? <CheckIcon /> : <CloseIcon />;
              }
            }
          }}
        />
        <BooleanField source="exclude_from_stats" />
        <FunctionField
          label="Phone"
          render={({ id }) => {
            for (const option of storeCommunicationData) {
              if (option.store_id === id) {
                return option.twilio_phone;
              }
            }
          }}
        />
        <FunctionField
          label="Tags"
          render={({ tags }) => <ChipTags tags={tags} />}
        />
        <FunctionField
          label="Assigned Users"
          render={({ id }) => {
            const userCache = {};
            const renderedUsers = [];

            userRolesData.forEach(({ store_id, user }) => {
              if (store_id === id && !userCache[user.email]) {
                userCache[user.email] = true;
                renderedUsers.push(
                  <Chip key={user.email} label={user.email} size="small" />,
                );
              }
            });

            return renderedUsers;
          }}
        />
        <DateField showTime source="created_at" />
        <DateField showTime source="updated_at" />
      </Datagrid>
    </List>
  );
};

export default StoreList;
