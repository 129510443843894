import React from 'react';
import { TopToolbar } from 'react-admin';

import VehicleEditStatus from './VehicleEditStatus';

const VehicleEditActions = ({ onEditStatusSuccess, status, id }) => (
  <TopToolbar>
    <VehicleEditStatus
      onSuccess={onEditStatusSuccess}
      selectedIds={[id]}
      status={status}
    />
  </TopToolbar>
);

export default VehicleEditActions;
