import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

import { ToolbarEdit } from '../ui';

const ContactEdit = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<ToolbarEdit />}>
      <TextInput disabled source="id" />
      <TextInput source="mobile" />
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export default ContactEdit;
