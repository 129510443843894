import {
  useNotify,
  useRefresh,
  useUnselectAll,
  useDeleteMany as useDeleteManyRA,
} from 'react-admin';

/**
 * Override RA's useDeleteMany with default success and error behavior
 *
 * API: https://marmelab.com/react-admin/Actions.html#useupdatemany
 */
export const useDeleteMany = (
  resource,
  ids,
  { onSuccess, onFailure, ...options } = {},
) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll();

  const handleSuccess = () => {
    onSuccess?.();
    notify(`${ids?.length ?? 0} records deleted`);
    refresh();
    unselectAll(resource);
  };

  const handleFailure = () => {
    onFailure?.();
    notify('An error has occurred. Please try again later.', 'warning');
  };

  return useDeleteManyRA(resource, ids, {
    ...options,
    onSuccess: handleSuccess,
    onFailure: handleFailure,
  });
};
