import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  DateField,
  // FunctionField,
  List,
  NumberField,
  ReferenceField,
  // ReferenceInput,
  // SelectInput,
  TextField,
  TextInput,
} from "react-admin";

import { VehicleStatusFindAllByVehicleIdGql } from "../gql";
// import { Years } from "../lib";
import { Datagrid, Pagination } from "../ui";
// import VehicleListActions from "./VehicleListActions";
// import VehicleStatusChips from "./VehicleStatusChips";
import { makeStyles } from "@material-ui/core";

const VehicleList = (props) => {
  const [vehicleIds, setVehicleIds] = useState([]);
  // const { data, refetch } = useQuery(VehicleStatusFindAllByVehicleIdGql, {
  //   variables: { ids: vehicleIds },
  // });
  const { data } = useQuery(VehicleStatusFindAllByVehicleIdGql, {
    variables: { ids: vehicleIds },
  });

  const useStyles = makeStyles({
    row: {
      height: "5vh",
    },
  });
  const filters = [
    <TextInput
      alwaysOn
      label="Search"
      source="vin,make,model,trim,style,tags"
    />,
    <TextInput alwaysOn label="Rep" source="contact#name@_ilike" />,
    // <ReferenceInput
    //   alwaysOn
    //   label="Appraiser"
    //   source="contact_id"
    //   reference="contacts"
    // >
    //   <SelectInput optionText="name" resettable />
    // </ReferenceInput>,
  ];

  const vehicleStatusData = data?.vehicle_status;
  console.log(vehicleStatusData);

  const classes = useStyles();
  return (
    <List
      {...props}
      // bulkActionButtons={<VehicleListActions onStatusUpdateSuccess={refetch} />}
      bulkActionButtons={false}
      filters={filters}
      pagination={<Pagination />}
      sort={{
        field: "created_at",
        order: "DESC",
      }}
    >
      <Datagrid
        classes={{ row: classes.row }}
        onPageLoad={setVehicleIds}
        rowClick="show"
      >
        <TextField source="vin" />
        <TextField source="year" />
        <TextField source="make" />
        <TextField source="model" />
        <TextField source="style" />
        <TextField source="trim" />
        <NumberField source="mi" />
        <NumberField source="offer" />

        <ReferenceField
          label="Appraiser Name"
          source="contact_id"
          reference="contacts"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Appraiser Email"
          source="contact_id"
          reference="contacts"
          link={false}
        >
          <TextField source="email" />
        </ReferenceField>
        {/* <ReferenceField source="store_id" reference="stores">
          <TextField source="name" />
        </ReferenceField> */}
        <DateField source="created_at" />
        {/* <TextField source="source_platform" /> */}
        {/* <FunctionField
          label="Tags"
          render={({ tags }) => <ChipTags tags={tags} />}
        />
        <FunctionField
          label="Status"
          render={({ id }) => (
            <VehicleStatusChips id={id} vehicleStatus={vehicleStatusData} />
          )}
        /> */}
      </Datagrid>
    </List>
  );
};

export default VehicleList;
