import { gql } from '@apollo/client';

export const AuthLoginGql = gql`
  query AuthLogin($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      email
      id
      token
    }
  }
`;
