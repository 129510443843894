import Locale from '../locale/en';
import RequestInspectionRawHTML from '../static/emails/request-inspection';

const RequestInspectionFrom = 'appraisals@mail1.drivably.com';
const RequestInspectionFromAlias = 'Drivably Appraisal';
const RequestInspectionDealerNameLeeAuto = 'Lee Auto';

const RequestInspectionTo = 'acvoffers@acvauctions.com';

export const buildRequestInspectionEmail = ({
  appraisalDate,
  city = Locale.NA,
  country,
  mileage = 0,
  offer,
  offerExpirationDate,
  postalCode,
  state,
  storeName,
  streetAddress,
  userEmail,
  userName,
  vin,
  yearMakeModel,
  disclosureAnswers = {},
  disclosureQuestions = [],
}) => {
  const requestDate = new Date();

  const disclosureContent = createDisclosureContent(
    disclosureQuestions,
    disclosureAnswers,
  );

  const body = RequestInspectionRawHTML.replace(
    /{{date}}/g,
    requestDate.toLocaleDateString('en-US'),
  )
    .replace(/{{dealerName}}/g, RequestInspectionDealerNameLeeAuto)
    .replace(/{{storeName}}/g, storeName)
    .replace(/{{userName}}/g, userName)
    .replace(/{{userEmailAddress}}/g, userEmail)
    .replace(/{{streetAddress}}/g, streetAddress)
    .replace(/{{city}}/g, city)
    .replace(/{{state}}/g, state)
    .replace(/{{postalCode}}/g, postalCode)
    .replace(/{{country}}/g, country)
    .replace(/{{appraisalDate}}/g, appraisalDate)
    .replace(/{{yearMakeModel}}/g, yearMakeModel)
    .replace(/{{offer}}/g, offer)
    .replace(/{{offerExpirationDate}}/g, offerExpirationDate)
    .replace(/{{vin}}/g, vin)
    .replace(/{{mileage}}/g, mileage.toLocaleString())
    .replace(/{{disclosureContent}}/g, disclosureContent);

  return {
    to: RequestInspectionTo,
    toAlias: RequestInspectionTo,
    from: RequestInspectionFrom,
    fromAlias: RequestInspectionFromAlias,
    body,
    subject: Locale.RequestInspectionEmailSubject,
  };
};

const createDisclosureContent = (questions, answers) => {
  const len = questions?.length;

  return questions
    .map((q, i) => {
      if (shouldSkipQuestion(q)) {
        return '';
      }

      // Force capitalize each word
      let { label } = q;
      if (label) {
        label = label
          .split(' ')
          .map((w) => {
            try {
              return w[0].toUpperCase() + w.substring(1);
            } catch (err) {
              return w;
            }
          })
          .filter(Boolean)
          .join(' ')
          .trim();

        label = label ? `${label}:` : '';
      }

      let answer = answers[q.key] ?? '';
      if (Array.isArray(answer)) {
        answer = answer.join(', ');
      } else if (typeof answer === 'boolean') {
        answer = answer
          ? DisclosureBooleanType.True
          : DisclosureBooleanType.False;
      }

      answer = answer.trim();

      // Skip if no answer and is a sub question
      if (!answer && q.meta?.parentKey) {
        return '';
      }

      if (!label) {
        // Italicize sub question answers
        answer = `<i>${answer}</i>`;
      } else {
        // Set N/A to empty main questions
        answer = answer || Locale.NA;
      }

      return `
    <tr>
      <td style="font-size: 16px; padding-bottom: ${
        i === len - 1 ? '30' : '10'
      }px">
        ${label} ${answer}
      </td>
    </tr>
`;
    })
    .join('\n');
};

export const DisclosureBooleanType = {
  True: 'Yes',
  False: 'No',
};

export const DisclosureQuestionType = {
  Boolean: 'boolean',
  Checkbox: 'checkbox',
  Int: 'int',
  Radio: 'radio',
  String: 'string',
};

// Skip string and int types like vin, make and model. These are natively asked in app.
export const shouldSkipQuestion = ({ type, key } = {}) =>
  [DisclosureQuestionType.String, DisclosureQuestionType.Int].includes(type) ||
  key === 'color';
