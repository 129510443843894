import React, { Children, cloneElement } from 'react';
import { useRedirect } from 'react-admin';

import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const DatagridRow = ({
  basePath,
  children,
  className,
  hasBulkActions,
  id,
  onToggleItem,
  record,
  resource,
  rowClick,
  selectable,
  selected,
}) => {
  const redirect = useRedirect();

  return (
    <TableRow
      className={className}
      key={id}
      onClick={(event) =>
        event.target.type !== 'checkbox' &&
        redirect(rowClick, `/${resource}`, id)
      }
    >
      {hasBulkActions && (
        <TableCell padding="checkbox">
          <Checkbox
            disabled={!selectable}
            checked={selected}
            onClick={(event) => onToggleItem(id, event)}
          />
        </TableCell>
      )}
      {Children.map(children, (field) => (
        <TableCell key={`${id}-${field.props.source}`}>
          {cloneElement(field, {
            basePath,
            record,
            resource,
          })}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default DatagridRow;
