import * as React from "react";
import {
  Datagrid,
  EmailField,
  TextField,
  TextInput,
  List,
  TopToolbar,
  Button,
  ExportButton,
} from "react-admin";
import { history } from "../../history";

export const CustomToolbar = (props) => {
  return (
    <TopToolbar {...props}>
      <ExportButton />
      <Button
        label="Make Admin"
        onClick={() => history.push(`/appraisal_admin_stores/create?source={}`)}
      />
      <Button
        label="Make Appraiser"
        onClick={() => history.push(`/appraisal_user_stores/create?source={}`)}
      />
    </TopToolbar>
  );
};

export const UserList = (props) => {
  const filters = [<TextInput alwaysOn label="Search" source="name" />];
  return (
    <List
      {...props}
      filters={filters}
      actions={<CustomToolbar {...props} />}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <EmailField source="email" />
      </Datagrid>
    </List>
  );
};
