import { gql } from '@apollo/client';

export const VehicleFindAllStoreTagsByIdGql = gql`
  query VehicleFindAllStoreTagsById($id: Int!) {
    vehicles(where: { id: { _eq: $id } }) {
      id
      store {
        id
        tags
      }
    }
  }
`;

export const VehicleFindByIdGql = gql`
  query VehicleFindById($id: Int!) {
    vehicles(where: { id: { _eq: $id } }) {
      id
      vin
      year
      make
      model
      style
      trim
      offer
      offer_range
      created_at
      km
      store {
        account_id
        address
        city
        country
        id
        name
        province_state
        zip
      }
      user {
        name
        email
      }
      extra
    }
  }
`;
