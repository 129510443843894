import { gql } from '@apollo/client';

export const StoreBotOptionFindAllByStoreIdGql = gql`
  query StoreBotOptionFindAllByStoreId($ids: [Int!]) {
    store_bot_option(where: { store_id: { _in: $ids } }) {
      pause
      store_id
    }
  }
`;

export const StoreBotOptionUpdateByStoreIdGql = gql`
  mutation StoreBotOptionUpdateByStoreId(
    $store_id: Int!
    $_set: store_bot_option_set_input!
  ) {
    update_store_bot_option_by_pk(
      pk_columns: { store_id: $store_id }
      _set: $_set
    ) {
      store_id
    }
  }
`;
