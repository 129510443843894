import { gql } from '@apollo/client';

export const VehicleStatusFindAllByVehicleIdGql = gql`
  query VehicleStatusFindAllByVehicleId($ids: [Int!]) {
    vehicle_status(
      where: { vehicle_id: { _in: $ids } }
      distinct_on: vehicle_id
    ) {
      active
      appointment
      dismissed
      disqualified
      dormant
      follow_up
      sent_to_crm
      unavailable
      vehicle_id
    }
  }
`;

export const VehicleStatusUpdateByVehicleIdGql = gql`
  mutation VehicleStatusUpdateByVehicleId(
    $vehicle_id: Int!
    $_set: vehicle_status_set_input!
  ) {
    update_vehicle_status_by_pk(
      pk_columns: { vehicle_id: $vehicle_id }
      _set: $_set
    ) {
      vehicle_id
    }
  }
`;
