// prettier-ignore

const Locale = {
  NA: 'N/A',
  City: 'City',
  Country: 'Country',
  PostalCode: 'Postal Code',
  State: 'State',
  Street: 'Street',
  VIN: 'VIN',
  RequestInspectionButton: 'Inspect',
  RequestInspectionAddressLine1: 'Address Line 1',
  RequestInspectionAddressLine2: 'Address Line 2',
  RequestInspectionConfirmDescription: 'Are you sure you want to request inspection for the vehicle?',
  RequestInspectionEmailSubject: 'Inspection Request - Drivably Appraisal',
  RequestInspectionSaveButtonText: 'Send Request',
  RequestInspectionSaveDescription: 'Please confirm the address for the location where this inspection will occur.',
  RequestInspectionSaveTitle: 'Request Inspection',
  RequestInspectionStore: 'Store (Optional)',
  RequestInspectionYearMakeModel: 'Year Make Model',
};

export default Locale;
