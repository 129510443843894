import { gql } from '@apollo/client';

export const ActionGetPhoneNumbersGql = gql`
  query ActionGetPhoneNumbers(
    $areaCode: Int
    $city: String
    $countryCode: String!
    $state: String
  ) {
    getPhoneNumbers(
      country_code: $countryCode
      city: $city
      area_code: $areaCode
      state: $state
    ) {
      city
      country_code
      formatted_phone_number
      has_mms
      has_sms
      has_voice
      phone_number
      state
    }
  }
`;

export const ActionCreatePhoneNumberGql = gql`
  mutation ActionCreatePhoneNumber($phoneNumber: String!) {
    createPhoneNumber(phone_number: $phoneNumber) {
      account_id
      formatted_phone_number
      has_mms
      has_sms
      has_voice
      id
      phone_number
      sms_url
      voice_url
    }
  }
`;
