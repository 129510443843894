export const VehicleStatus = {
  Active: 'active',
  Appointment: 'appointment',
  Dismissed: 'dismissed',
  Disqualified: 'disqualified',
  FollowUp: 'follow_up',
  Unavailable: 'unavailable',
};

export const VehicleStatusDefault = {
  active: false,
  appointment_full: false,
  appointment: false,
  archived: false,
  contacted: false,
  dismissed: false,
  disqualified: false,
  dormant: false,
  follow_up: false,
  human_appraised: false,
  incomplete: false,
  machine_appraised: false,
  purchased: false,
  qualified: false,
  sent_to_crm: false,
  unavailable: false,
};

export const getVehicleStatusLabel = (value) => {
  switch (value) {
    case VehicleStatus.Active:
      return 'Active';
    case VehicleStatus.Appointment:
      return 'Appointment';
    case VehicleStatus.Dismissed:
      return 'Dismissed';
    case VehicleStatus.Disqualified:
      return 'Disqualified';
    case VehicleStatus.FollowUp:
      return 'FollowUp';
    case VehicleStatus.Unavailable:
      return 'Unavailable';
    default:
      if (value) {
        console.error(`Unknown status ${value}`);
      }

      return '';
  }
};

export const VehicleStatusValues = Object.values(VehicleStatus);

/**
 * Create an array of status strings from object with keys as status and boolean values
 *
 * @param {object} obj - graphql status document
 * @returns {string[]} status array strings
 */
export const getStatusListFromObject = (obj = {}) => {
  return Object.entries(obj).reduce((list, [key, enabled]) => {
    if (enabled && !['__typename', 'vehicle_id'].includes(key)) {
      list.push(key);
    }

    return list;
  }, []);
};
