import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import ModalConfirm from './ModalConfirm';

const Mode = {
  Hidden: 'hidden',
  Input: 'input',
  Confirm: 'confirm',
};

/** ModalAction allows custom single/bulk actions with modal dialog and confirmation. */
const ModalAction = ({
  backButtonText = 'Back',
  cancelButtonText = 'Cancel',
  children,
  confirmButtonText = 'Confirm',
  confirmContent = null,
  confirmDescription = '',
  confirmTitle = '',
  isBulk = true,
  isConfirmDisabled = false,
  isSaveDisabled = false,
  isLoading = false,
  isOpen = false,
  onCancel,
  onClose,
  onConfirm,
  onSave,
  saveButtonText = 'Save',
  saveDescription = '',
  saveTitle = '',
}) => {
  const [mode, setMode] = useState(Mode.None);
  const classes = useStyles();

  useEffect(() => setMode(isOpen ? Mode.Input : Mode.Hidden), [isOpen]);

  const proceedToConfirmation = () => setMode(Mode.Confirm);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={onClose}
        open={mode === Mode.Input}
      >
        {saveTitle && <DialogTitle>{saveTitle}</DialogTitle>}
        <form>
          <DialogContent>
            {saveDescription && (
              <DialogContentText>{saveDescription}</DialogContentText>
            )}
            {children}
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel || onClose}>{cancelButtonText}</Button>
            <div className={classes.buttonWrapper}>
              <Button
                disabled={
                  isSaveDisabled ||
                  (!isBulk && (isLoading || isConfirmDisabled))
                }
                variant="contained"
                color="primary"
                onClick={(event) => {
                  if (isBulk) {
                    if (onSave) {
                      onSave(event, { proceedToConfirmation });
                    } else {
                      proceedToConfirmation();
                    }

                    return;
                  }

                  onConfirm?.(event);
                }}
              >
                {saveButtonText}
              </Button>
              {!isBulk && isLoading && (
                <CircularProgress size={20} className={classes.buttonLoader} />
              )}
            </div>
          </DialogActions>
        </form>
      </Dialog>
      <ModalConfirm
        cancelButtonText={backButtonText}
        confirmButtonText={confirmButtonText}
        description={confirmDescription}
        isConfirmDisabled={isConfirmDisabled}
        isLoading={isLoading}
        isOpen={mode === Mode.Confirm}
        onCancel={() => setMode(Mode.Input)}
        onClose={onClose}
        onConfirm={onConfirm}
        title={confirmTitle || saveTitle}
      >
        {confirmContent}
      </ModalConfirm>
    </>
  );
};

ModalAction.propTypes = {
  backButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  children: PropTypes.node.isRequired,
  confirmButtonText: PropTypes.string,
  confirmDescription: PropTypes.string.isRequired,
  confirmTitle: PropTypes.string,
  isBulk: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  saveButtonText: PropTypes.string,
  saveDescription: PropTypes.string,
  saveTitle: PropTypes.string,
};

export default ModalAction;

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonLoader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
