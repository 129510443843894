import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

/** ModalConfirm shows confirmation dialog. */
const ModalConfirm = ({
  cancelButtonText = 'Cancel',
  children = null,
  confirmButtonText = 'Confirm',
  description = '',
  isConfirmDisabled = false,
  isLoading = false,
  isOpen = false,
  onCancel,
  onClose,
  onConfirm,
  title = '',
}) => {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel || onClose}>{cancelButtonText}</Button>
        <div className={classes.buttonWrapper}>
          <Button
            color="primary"
            disabled={isLoading || isConfirmDisabled}
            onClick={onConfirm}
            variant="contained"
          >
            {confirmButtonText}
          </Button>
          {isLoading && (
            <CircularProgress size={20} className={classes.buttonLoader} />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

ModalConfirm.propTypes = {
  cancelButtonText: PropTypes.string,
  children: PropTypes.node.isRequired,
  confirmButtonText: PropTypes.string,
  description: PropTypes.string.isRequired,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default ModalConfirm;

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonLoader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
