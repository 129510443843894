import React, { useEffect } from 'react';
import { Datagrid as DatagridRA } from 'react-admin';

import DatagridBody from './DatagridBody';

/**
 * Override RA's Datagrid component to share current page IDs for field components
 *
 * API: https://marmelab.com/react-admin/List.html#the-datagrid-component
 */
const Datagrid = ({ ids, onPageLoad, ...props }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onPageLoad?.(ids), [ids]);

  return <DatagridRA {...props} body={<DatagridBody />} ids={ids} />;
};

export default Datagrid;
