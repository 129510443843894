import * as React from "react";
import { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useMutation, useQuery } from "@apollo/client";
import { useNotify, Title, usePermissions } from "react-admin";
import { Roles } from "../roles";
import { makeStyles } from "@material-ui/core/styles";
import {
  CardHeader,
  TextField,
  Button,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
import { ASSIGN_ADMIN, ASSIGN_USER, CREATE_USER, STORES } from "./graphql";
import Stats from "./Stats";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiButton-root": {
      margin: theme.spacing(1),
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  },
}));

const Dashboard = () => {
  const role = usePermissions();
  const classes = useStyles();
  const notify = useNotify();

  const [form, setForm] = useState({ role: "appraiser" });

  const stores = useQuery(STORES);

  const [assignAdmin] = useMutation(ASSIGN_ADMIN, {
    onError: (e) => notify(e.message, "error"),
    onCompleted: () => notify("User Created", "success"),
  });

  const [assignUser] = useMutation(ASSIGN_USER, {
    onError: (e) => notify(e.message, "error"),
    onCompleted: () => notify("User Created", "success"),
  });

  const [createUserMut] = useMutation(CREATE_USER, {
    onError: (e) => notify(e.message, "error"),
    onCompleted: (data) => {
      if (form.role === Roles.AppraisalAdmin) {
        assignAdmin({
          variables: { store_id: form.store_id, user_id: data.createUser.id },
        });
      } else {
        assignUser({
          variables: { store_id: form.store_id, user_id: data.createUser.id },
        });
      }
    },
  });

  const createUserSubmit = () => {
    if (!form.store_id) {
      alert("You need to select the a store user will be assigned to");
      return;
    }

    if (form.role === "admin") {
      createUserMut({
        variables: {
          name: form.name,
          email: form.email,
          password: "janika@123",
          role: Roles.AppraisalAdmin,
        },
      });
    } else {
      createUserMut({
        variables: {
          name: form.name,
          email: form.email,
          password: "janika@123",
          role: Roles.AppraisalUser,
        },
      });
    }
  };

  const changeRole = (e) => {
    setForm((p) => {
      return { ...p, role: e.target.value };
    });
  };

  const changeStore = (e) => {
    setForm((p) => {
      return { ...p, store_id: e.target.value };
    });
  };
  console.log(role);
  return (
    <div>
      <Title title="Drivably Portal" />
      <Card>
        {role.loaded && (
          <Stats dash_id={26} user_id={role?.permissions?.userID} />
        )}
      </Card>
      {role?.permissions?.role === Roles.AppraisalAdmin && (
        <Card>
          <CardHeader title="Register new user" />
          <CardContent>
            <form className={classes.root} noValidate autoComplete="off">
              <FormControl variant="filled" className={classes.root}>
                <InputLabel id="demo-simple-select-filled-label">
                  Store
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={form.store_id}
                  onChange={changeStore}
                >
                  {!stores.loading &&
                    stores.data &&
                    stores.data.stores.map((s) => {
                      return <MenuItem value={s.id}>{s.name}</MenuItem>;
                    })}
                </Select>
              </FormControl>
              <FormControl variant="filled" className={classes.root}>
                <InputLabel id="demo-simple-select-filled-label">
                  Role
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={form.role}
                  onChange={changeRole}
                >
                  <MenuItem value="admin">Admin</MenuItem>;
                  <MenuItem value="appraiser">Appraiser</MenuItem>;
                </Select>
              </FormControl>
              <br></br>
              <TextField
                id="filled-secondary"
                label="Name"
                variant="filled"
                color="secondary"
                onChange={(e) =>
                  setForm((p) => {
                    return { ...p, name: e.target.value };
                  })
                }
              />
              <TextField
                id="filled-secondary"
                label="Email"
                variant="filled"
                color="secondary"
                onChange={(e) =>
                  setForm((p) => {
                    return { ...p, email: e.target.value };
                  })
                }
              />
              {/* <TextField
                margin="10px"
                id="filled-secondary"
                label="Password"
                variant="filled"
                color="secondary"
                onChange={(e) =>
                  setForm((p) => {
                    return { ...p, password: e.target.value };
                  })
                }
              /> */}
              <Button
                onClick={() => createUserSubmit()}
                variant="contained"
                color="primary"
                size="large"
              >
                Register
              </Button>
            </form>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default Dashboard;
