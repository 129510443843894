import jwt from "jwt-decode";

export const Roles = {
  AppraisalUser: "appraisal_user",
  AppraisalAdmin: "appraisal_admin",
  DrivablyAdmin: "drivably_admin",
};

export const decodeJWT = (token) => {
  return jwt(token);
};

export const getRole = (token) => {
  const {
    "https://hasura.io/jwt/claims": { "x-hasura-default-role": role },
  } = decodeJWT(token);

  return role;
};

export const getUserID = (token) => {
  const {
    "https://hasura.io/jwt/claims": { "x-hasura-user-id": userID },
  } = decodeJWT(token);

  return userID;
};
