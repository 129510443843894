import React from 'react';
import { List, Datagrid, TextField, TextInput, DateField } from 'react-admin';

import { Pagination } from '../ui';

const AccountList = (props) => {
  const filters = [<TextInput alwaysOn label="Search" source="name" />];

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={filters}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <DateField showTime source="created_at" />
        <DateField showTime source="updated_at" />
      </Datagrid>
    </List>
  );
};

export default AccountList;
