const rawHTML = `
<!DOCTYPE html>
<html>
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <meta name="viewport" content="width=device-width; maximum-scale=1.0;" />
    <meta name="x-apple-disable-message-reformatting" />
    <!--fixes the scaling issue on mobile devices-->
    <meta name="color-scheme" content="light dark" />
    <meta name="supported-color-schemes" content="light dark" />
    <style>
      @media (prefers-color-scheme: dark) {
        h1,
        h2,
        p,
        span,
        a,
        b {
          color: #fff !important;
        }
      }

      [data-ogsc] h1,
      [data-ogsc] h2,
      [data-ogsc] p,
      [data-ogsc] span,
      [data-ogsc] a,
      [data-ogsc] b {
        color: #fff !important;
      }

      .step-title {
        font-size: 18px;
        font-weight: bold;
        padding: 5px 0 0 0;
      }

      .step-subtitle {
        font-size: 16px;
        color: #6a6f81;
        padding: 0 0 15px 0;
      }
    </style>
  </head>
  <body>
    <span
      style="
        color: transparent;
        visibility: hidden;
        display: none;
        opacity: 0;
        height: 0;
        width: 0;
        font-size: 0;
      "
    ></span>
    <table
      role="presentation"
      class="full-width"
      style="table-layout: fixed; margin: 0p auto"
      width="100%"
      border="0"
      cellspacing="0"
      cellpadding="0"
      align="center"
    >
      <tbody style="font-family: Roboto, sans-serif; text-align: center">
        <tr>
          <!-- Header -->
          <td
            style="
              font-size: 28px;
              font-weight: bold;
              padding-top: 25px;
              padding-bottom: 50px;
            "
          >
            Inspection Request - Drivably Appraisal
          </td>
        </tr>
        <tr>
          <!-- Request Details Header-->
          <td style="font-size: 16px; font-weight: bold; padding-bottom: 15px">
            Request Details
          </td>
        </tr>
        <tr>
          <!-- Date -->
          <td style="font-size: 16px; padding-bottom: 10px">Date: {{date}}</td>
        </tr>
        <tr>
          <!-- Dealer Name -->
          <td style="font-size: 16px; padding-bottom: 10px">
            Dealer Name: {{dealerName}}
          </td>
        </tr>
        <tr>
          <!-- Store -->
          <td style="font-size: 16px; padding-bottom: 10px">
            Store: {{storeName}}
          </td>
        </tr>
        <tr>
          <!-- User Name -->
          <td style="font-size: 16px; padding-bottom: 10px">
            User Name: {{userName}}
          </td>
        </tr>
        <tr>
          <!-- User Email Address -->
          <td style="font-size: 16px; padding-bottom: 10px">
            User Email Address: {{userEmailAddress}}
          </td>
        </tr>
        <tr>
          <!-- Inspection Location Header-->
          <td
            style="
              font-size: 16px;
              font-weight: bold;
              padding-top: 40px;
              padding-bottom: 15px;
            "
          >
            Inspection Location
          </td>
        </tr>
        <tr>
          <!-- Street Address -->
          <td style="font-size: 16px; padding-bottom: 10px">
            Street Address: {{streetAddress}}
          </td>
        </tr>
        <tr>
          <!-- City -->
          <td style="font-size: 16px; padding-bottom: 10px">City: {{city}}</td>
        </tr>
        <tr>
          <!-- State/Provide -->
          <td style="font-size: 16px; padding-bottom: 10px">
            State/Provide: {{state}}
          </td>
        </tr>
        <tr>
          <!-- Postal Code -->
          <td style="font-size: 16px; padding-bottom: 10px">
            Postal Code: {{postalCode}}
          </td>
        </tr>
        <tr>
          <!-- Country -->
          <td style="font-size: 16px; padding-bottom: 10px">
            Country: {{country}}
          </td>
        </tr>
        <tr>
          <td
            style="
              font-size: 16px;
              font-weight: bold;
              padding-top: 40px;
              padding-bottom: 15px;
            "
          >
            Drivably Offer Details
          </td>
        </tr>
        <tr>
          <td style="font-size: 16px; padding-bottom: 10px">
            Appraisal Date: {{appraisalDate}}
          </td>
        </tr>
        <tr>
          <td style="font-size: 16px; padding-bottom: 10px">
            Vehicle: {{yearMakeModel}}
          </td>
        </tr>
        <tr>
          <td style="font-size: 16px; padding-bottom: 10px">
            Offer: {{offer}}
          </td>
        </tr>
        <tr>
          <td style="font-size: 16px; padding-bottom: 10px">
            Offer Expiration: Expires on {{offerExpirationDate}}
          </td>
        </tr>
        <tr>
          <td
            style="
              font-size: 16px;
              font-weight: bold;
              padding-top: 40px;
              padding-bottom: 15px;
            "
          >
            Vehicle Details
          </td>
        </tr>
        <tr>
          <td style="font-size: 16px; padding-bottom: 10px">VIN: {{vin}}</td>
        </tr>
        <tr>
          <td style="font-size: 16px; padding-bottom: 10px">
            Offer: {{offer}}
          </td>
        </tr>
        <tr>
          <td style="font-size: 16px; padding-bottom: 10px">
            Year Make Model: {{yearMakeModel}}
          </td>
        </tr>
        <tr>
          <td style="font-size: 16px; padding-bottom: 10px">
            Odometer: {{mileage}}
          </td>
        </tr>
        {{disclosureContent}}
      </tbody>
    </table>
    <table
      role="presentation"
      class="full-width"
      style="table-layout: fixed; margin: 0p auto"
      width="100%"
      border="0"
      cellspacing="0"
      cellpadding="0"
      align="center"
    >
      <tbody style="font-family: Roboto, sans-serif; text-align: center">
        <tr>
          <!-- Horizontal Line -->
          <td><hr style="width: 50%" /></td>
        </tr>
        <tr>
          <!-- FAQs -->
          <td
            style="
              font-size: 32px;
              font-weight: bold;
              padding: 25px 0px 15px 0px;
            "
          >
            Contact Us
          </td>
        </tr>
        <tr>
          <td style="padding-bottom: 25px">
            <i
              >Have questions? Need more help? Email
              <a href="mailto:support@drivably.com">support@drivably.com</a>.</i
            >
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
    <br />
    <center>
      <div style="line-height: inherit; background-color: transparent">
        <div
          style="
            line-height: inherit;
            min-width: 320px;
            max-width: 600px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            margin: 0 auto;
            background-color: transparent;
          "
        >
          <div
            style="
              line-height: inherit;
              border-collapse: collapse;
              display: table;
              width: 100%;
              background-color: transparent;
            "
          >
            <div
              style="
                line-height: inherit;
                min-width: 320px;
                max-width: 600px;
                display: table-cell;
                vertical-align: top;
                width: 500px;
              "
            >
              <div style="line-height: inherit; width: 100% !important">
                <div
                  style="
                    line-height: inherit;
                    border-top: 0px solid transparent;
                    border-left: 0px solid transparent;
                    border-bottom: 0px solid transparent;
                    border-right: 0px solid transparent;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    padding-right: 0px;
                    padding-left: 0px;
                  "
                >
                  <div
                    style="
                      color: #555555;
                      font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;
                      line-height: 1.2;
                      padding-top: 10px;
                      padding-right: 10px;
                      padding-bottom: 10px;
                      padding-left: 10px;
                    "
                  >
                    <div
                      style="
                        line-height: 1.2;
                        font-size: 12px;
                        font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;
                        color: #555555;
                      "
                    >
                      <p
                        style="
                          font-size: 12px;
                          line-height: 1.2;
                          word-break: break-word;
                          text-align: center;
                          font-family: Roboto, Tahoma, Verdana, Segoe,
                            sans-serif;
                        "
                      >
                        <span style="line-height: inherit; font-size: 12px"
                          >(480) 702-1010 | 3133 W Frye Rd, Suite 101 Chandler,
                          AZ 85226 |
                          <a href="mailto:hello@drivably.com"
                            >hello@drivably.com</a
                          ></span
                        >
                      </p>
                      <p
                        style="
                          font-size: 12px;
                          line-height: 1.2;
                          word-break: break-word;
                          text-align: center;
                          font-family: Roboto, Tahoma, Verdana, Segoe,
                            sans-serif;
                        "
                      >
                        2021 Drivably, Inc.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </center>
  </body>
</html>
`;

export default rawHTML;
