import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import {
  Button as ButtonRA,
  useNotify,
  useRefresh,
  useUnselectAll,
} from 'react-admin';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import { ModalAction } from '../ui';
import { VehicleStatusUpdateByVehicleIdGql } from '../gql';
import { useToggle } from '../hooks';
import {
  getVehicleStatusLabel,
  VehicleStatusDefault,
  VehicleStatusValues,
} from './lib';

const VehicleEditStatus = ({
  onSuccess,
  selectedIds = [],
  status: statusProp,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, toggleIsOpen] = useToggle(false);
  const [status, setStatus] = useState([]);

  useEffect(() => setStatus(statusProp || []), [statusProp]);

  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll();

  const [updateVehicles] = useMutation(VehicleStatusUpdateByVehicleIdGql);

  const handleConfirm = async () => {
    let isSuccess = true;
    setIsLoading(true);

    const newStatus = status.reduce((obj, val) => {
      obj[val] = true;
      return obj;
    }, {});

    for (const selectedId of selectedIds) {
      const { error } = await updateVehicles({
        variables: {
          vehicle_id: selectedId,
          _set: {
            ...VehicleStatusDefault,
            ...newStatus,
          },
        },
      });
      if (error) {
        isSuccess = false;
        notify('An error has occurred. Please try again later.', 'warning');
        break;
      }
    }

    setIsLoading(false);

    if (isSuccess) {
      notify(`${selectedIds.length} vehicle(s) updated`);
      toggleIsOpen();
      refresh();
      unselectAll('vehicles');
      onSuccess?.();
    }
  };

  const isBulk = selectedIds.length >= 2;
  const confirmDescription = `Are you sure you want to move ${
    selectedIds.length
  } vehicle(s) to status ${status.map(getVehicleStatusLabel).join(', ')}?`;

  const renderedSelectOptions = VehicleStatusValues.map((val) => (
    <MenuItem value={val} key={val}>
      {getVehicleStatusLabel(val)}
    </MenuItem>
  ));

  return (
    <>
      <ButtonRA label="Change Status" onClick={toggleIsOpen}>
        <LocalOfferIcon />
      </ButtonRA>
      <ModalAction
        confirmDescription={confirmDescription}
        isBulk={isBulk}
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={toggleIsOpen}
        onConfirm={handleConfirm}
        saveDescription={
          isBulk
            ? `Select new status for the vehicles.`
            : `Change status of the vehicle.`
        }
        saveTitle="Change Status"
      >
        <FormControl variant="filled" className={classes.formControl}>
          <InputLabel id="status-select-label">Status</InputLabel>
          <Select
            id="status-select"
            labelId="status-select-label"
            multiple
            onChange={(event) => setStatus(event.target.value || [])}
            value={status}
          >
            {renderedSelectOptions}
          </Select>
        </FormControl>
      </ModalAction>
    </>
  );
};

export default VehicleEditStatus;

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
}));
