import React, { useEffect } from "react";
import { useState } from "react";
import { useLogin, useNotify, Notification } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";

import { Logo } from "../ui";

const AppLogin = () => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const login = useLogin();
  const notify = useNotify();

  useEffect(() => {
    // Force refresh to prevent stats from being loaded for the previous user
    // Not sure where it's cached and why the query doesn't run with the new token in apollo client inside /Stats.js
    return () => window.location.reload();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    login({ username, password })
      .catch(() => notify("Invalid username or password"))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className={classes.page}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Logo className={classes.logo} />
        <TextField
          className={classes.username}
          id="username"
          name="username"
          label="Username"
          variant="outlined"
          value={username}
          fullWidth
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          id="password"
          name="password"
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          fullWidth
          onChange={(e) => setPassword(e.target.value)}
        />
        <Notification />
        <div className={classes.actions}>
          <Button
            color="primary"
            size="large"
            variant="contained"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Signing in" : "Sign In"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AppLogin;

const useStyles = makeStyles(() => ({
  page: {
    alignItems: "start",
    backgroundColor: "#040073",
    backgroundImage:
      "linear-gradient(161deg, #040073 0%, #004DCE 46%, #FFFFFF 100%)",
    height: "100vh",
    width: "100%",
    justifyContent: "center",
    display: "flex",
  },
  form: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    flexDirection: "column",
    marginTop: "80px",
    padding: "20px",
    borderColor: "darkgray",
    borderRadius: "5px",
    borderStyle: "solid",
    borderWidth: "1px",
    justifyItems: "center",
    minWidth: "350px",
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
  },
  username: {
    marginTop: "40px",
    marginBottom: "20px",
  },
  logo: {
    fill: "#2159f2",
    width: "130px",
  },
}));
