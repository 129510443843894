import React from 'react';
import { TopToolbar } from 'react-admin';

import StoreEditPhone from './StoreEditPhone';

const StoreEditActions = ({ onCreatePhoneSuccess, id, data: store }) => (
  <TopToolbar>
    <StoreEditPhone onSuccess={onCreatePhoneSuccess} id={id} store={store} />
  </TopToolbar>
);

export default StoreEditActions;
