import React from 'react';
import { useQuery } from '@apollo/client';
import { Show, SimpleShowLayout, TextField, EmailField } from 'react-admin';

import { MessageByUserIDCreatedAtGql } from '../gql';
import UserMessageField from './UserMessageField';
import { addDays } from '../lib';

const today = new Date();
const tomorrow = addDays(today, 1);

const todayDateStr = `${today.getUTCFullYear()}-${
  today.getUTCMonth() + 1
}-${today.getUTCDate()}T00:00:00+00:00`;

const tomorrowDateStr = `${tomorrow.getUTCFullYear()}-${
  tomorrow.getUTCMonth() + 1
}-${tomorrow.getUTCDate()}T00:00:00+00:00`;

const UserShow = ({ id, ...props }) => {
  const { data } = useQuery(MessageByUserIDCreatedAtGql, {
    variables: {
      user_id: id,
      created_at_from: todayDateStr,
      created_at_to: tomorrowDateStr,
    },
  });

  return (
    <Show {...props} id={id}>
      <SimpleShowLayout>
        <TextField source="id" />
        <EmailField source="email" />
        <TextField source="name" />
        <TextField source="mobile" />
        <UserMessageField
          label="Messages Today"
          addLabel
          messages={data?.messages}
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
